// DEFINITIONS

export const WAYPOINT_TYPES = {
    PASSENGER_START: 1,
    PASSENGER_STOP: 2,
    PARCEL_START: 3,
    PARCEL_STOP: 4,
    TRANSFER: 5,
    TASK: 6,
    START: 7,
    DRAFT: 8,
} as const;

export type WAYPOINT_TYPES = (typeof WAYPOINT_TYPES)[keyof typeof WAYPOINT_TYPES];
